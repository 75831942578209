import 'bootstrap';
import axios from 'axios';
import passwordValidator from 'password-validator';
import { serialize, hasEmptyValues } from './utils.js';


const schema = new passwordValidator();
schema
  .is().min(8)                                    // Minimum length 8
  .is().max(100)                                  // Maximum length 100
  .has().uppercase()                              // Must have uppercase letters
  .has().lowercase()                              // Must have lowercase letters
  .has().digits(2)                                // Must have at least 2 digits
  .has().not().spaces()                           // Should not have spaces
  .is().not().oneOf(['Passw0rd', 'Password123']); // Blacklist these values

const eyeBtns = document.querySelectorAll('.ic-eye');

if (eyeBtns.length) {
  Array.from(eyeBtns).forEach(item => {
    item.addEventListener('click', function () {
      const input = this.parentElement.querySelector('.form-control');
      if (input.getAttribute('type') === 'password') {
        input.setAttribute('type', 'text');
      } else {
        input.setAttribute('type', 'password');
      }
    })
  })
}

const qrCodeData = document.getElementById('qrCodeData');

if (qrCodeData && QRCode) {
  new QRCode(document.getElementById("qrCode"), {
    text: qrCodeData.getAttribute('data-url'),
    width: 150,
    height: 150
  });
}

const btnSendUpdatePass = document.getElementById('btn-update-password');

if (btnSendUpdatePass) {
  btnSendUpdatePass.addEventListener('click', function (e) {
    e.preventDefault();
    const form = document.getElementById('form-update-password');
    const data = serialize(new FormData(form));
    const errorsWrapper = document.querySelector('.errors-list');
    const infoWrapper = document.querySelector('.pass-info');

    if (hasEmptyValues(data)) {
      errorsWrapper.innerHTML = `<strong>Validation errors</strong>
                                 <ul>
                                  <li>The form has empty fields.</li>
                                 <ul>`;
      errorsWrapper.classList.remove('d-none');
      infoWrapper.classList.add('d-none');
    } else if (data.NewPassword != data.ConfirmPassword) {
      errorsWrapper.innerHTML = `<strong>Validation errors</strong>
                                 <ul>
                                  <li>The new password and confirmation password do not match.</li>
                                 <ul>`;
      errorsWrapper.classList.remove('d-none');
      infoWrapper.classList.add('d-none');
    } else if (!schema.validate(data.NewPassword)) {
      const validationErrors = schema.validate(data.NewPassword, { details: true });
      let errorsList = '';
      validationErrors.forEach(item => {
        errorsList += '<li>' + item.message + '</li>';
      });
      errorsWrapper.innerHTML = `<strong>Validation errors</strong>
                                 <ul>
                                  ${errorsList}
                                 <ul>`;
      errorsWrapper.classList.remove('d-none');
      infoWrapper.classList.add('d-none');
    } else {
      axios.post('/Account/UpdatePasswordPost', data).then(function (res) {
        const step1 = document.querySelector('.step1');
        const step2 = document.querySelector('.step2');
        const stepsbar = document.querySelector('.stepsbar');
        infoWrapper.classList.add('d-none');
        errorsWrapper.classList.add('d-none');
        step1.classList.add('d-none');
        step2.classList.remove('d-none');
        stepsbar.querySelector('li:first-child').classList.remove('active');
        stepsbar.querySelector('li:last-child').classList.add('active');
      })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 400) {
              let errorsList = '';
              error.response.data.forEach(item => {
                errorsList += '<li>' + item + '</li>';
              });
              errorsWrapper.innerHTML = `<strong>Validation errors</strong>
                                   <ul>
                                    ${errorsList}
                                   <ul>`;
              errorsWrapper.classList.remove('d-none');
              infoWrapper.classList.add('d-none');
            }
          } else {
            errorsWrapper.innerHTML = `<strong>Error</strong>
                                   <ul>
                                    ${error.message}
                                   <ul>`;
            errorsWrapper.classList.remove('d-none');
            infoWrapper.classList.add('d-none');
          }
        });
    }
  });
}

const btnSendCode = document.getElementById('btn-send-code');

if (btnSendCode) {
  btnSendCode.addEventListener('click', function (e) {
    e.preventDefault();
    const form = document.getElementById('form-send-code');
    const data = serialize(new FormData(form));
    const errorsWrapper = document.querySelector('.errors-list');

    if (hasEmptyValues(data)) {
      errorsWrapper.innerHTML = `<strong>Validation errors</strong>
                                 <ul>
                                  <li>The form has empty fields.</li>
                                 <ul>`;
      errorsWrapper.classList.remove('d-none');
    } else {
      axios.post('/Account/SetupAccountPost', data).then(function (res) {
        const formContinue = document.querySelector('.continue-form');
        console.log(res.data);
        const codes = res.data;

        if (Array.isArray(codes)) {
          document.querySelector('.enable-list').classList.add('d-none');
          document.querySelector('.codes-list').classList.remove('d-none');
          let stringArr = '';

          for (var i = 0; i < codes.length; i += 2) {
            stringArr += '<p>' + codes[i] + ' ' + codes[i + 1] + '</p>';
          }

          const codesWrapper = document.getElementById('recovery-codes');
          if (codesWrapper) {
            codesWrapper.innerHTML = stringArr;
          }
          formContinue.classList.remove('d-none');
        }
      })
        .catch(function (error) {
          errorsWrapper.innerHTML = `<strong>Error</strong>
                                   <ul>
                                    ${error.message}
                                   <ul>`;
          errorsWrapper.classList.remove('d-none');
        });
    }
  });
}


var elem = document.getElementById('floatingPassword');
if (elem) {
  elem.addEventListener("focusout", changeLoginButtonColor);

  function changeLoginButtonColor() {
    var elem = document.getElementById('loginButton');
    elem.style.backgroundColor = '#006699';
  }
}


var verifyElem = document.getElementById('floatingVerify');
if (verifyElem) {
  verifyElem.addEventListener("focusout", changeVerifyButtonColor);

  function changeVerifyButtonColor() {
    const verifyElem = document.getElementById('verifyButton');
    verifyElem.style.backgroundColor = '#006699';
  }
}







