export function serialize(data) {
  let obj = {};
  for (let [key, value] of data) {
    if (obj[key] !== undefined) {
      if (!Array.isArray(obj[key])) {
        obj[key] = [obj[key]];
      }
      obj[key].push(value);
    } else {
      obj[key] = value;
    }
  }
  return obj;
}

export function hasEmptyValues(obj) {
  let hasEmpty = false;
  const keys = Object.keys(obj)

  if (keys.length === 0) {
    hasEmpty = true;
  } else {
    keys.forEach(key => {
      if (obj[key] === "") {
        hasEmpty = true;
      }
    });
  }

  return hasEmpty;
}
